import React from 'react';

const Fruits = () => {
  const fruits = [
    {
      id: 1,
      name: 'Apple',
      description: 'A round fruit with a red skin and a white flesh.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/f/f4/Honeycrisp.jpg',
    },
    {
      id: 2,
      name: 'Banana',
      description: 'A long, curved fruit with a yellow skin and a soft, creamy flesh.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/b/bb/Banana_on_whitebackground.jpg',
    },
    {
      id: 3,
      name: 'Orange',
      description: 'A round fruit with an orange skin and a juicy flesh.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/c/c4/2012-366-353_Juicing_It_%288290566278%29.jpg',
    },
    {
      id: 4,
      name: 'Grapes',
      description: 'A small, round fruit that grows in bunches.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/5/5e/Wine_grapes03.jpg',
    },
    {
      id: 5,
      name: 'Watermelon',
      description: 'A large, round fruit with green skin and a pink or red flesh.',
      imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/4/47/Taiwan_2009_Tainan_City_Organic_Farm_Watermelon_FRD_7962.jpg',
    },
  ];

  return (
    <div>
      <h1>Fruits</h1>
      {fruits.map((fruit) => (
        <div key={fruit.id}>
          <img src={fruit.imageUrl} width={200} height={200} alt={fruit.name} />
          <h2>{fruit.name}</h2>
          <p>{fruit.description}</p>
        </div>
      ))}
    </div>
  );
};

const App = () => {
  return (
    <div>
      <Fruits />
    </div>
  );
};

export default App;
